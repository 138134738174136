//

import React, { useEffect, useState, useRef } from 'react';
import { Outlet } from 'react-router-dom';
import { useSnackbar } from 'notistack';
// @mui
import { styled } from '@mui/material/styles';
import { Alert, Box, Container } from '@mui/material';
// components
import Page from '../../components/Page';
// redux
import { useDispatch, useSelector } from '../../redux/store';
import useAuth from '../../hooks/useAuth';
// indexDB
import {
  deleteIndexDBRecord,
  getAllIndexDBRecords,
} from '../../indexDB/indexdbUtilsFunctions';
import { INDEX_DB_CONFIG } from '../../indexDB/configDB';
// offline post
import { submitReportForm } from '../../redux/pwa-report/submitReport';
import { updateExistingReport } from '../../redux/pwa-report/existingReports';
import { createInventory } from '../../redux/scoutInventory/scoutInventory';
// internet connection hook
import useCheckInternet from '../../hooks/useCheckInternetConnection';
import * as serviceWorkerRegistration from '../../serviceWorkerRegistration';
import { LoadingButton } from '@mui/lab';

// ----------------------------------------------------------------------

const RootParentStyle = styled('div')(({ theme }) => ({
  height: '100%',
  overflow: 'auto',
  minHeight: '100vh',
  backgroundColor: theme.palette.primary.main,
  [theme.breakpoints.up('md')]: {
    backgroundColor: theme.palette.background.default,
  },
}));

const RootStyle = styled('div')(({ theme }) => ({
  borderBottomRightRadius: '40px',
  borderBottomLeftRadius: '40px',
  position: 'relative',
  height: '46vh',
  backgroundColor: theme.palette.background.default,
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const ContentStyle = styled('div')(() => ({
  maxWidth: 356,
  margin: 'auto',
  position: 'relative',
  top: '4vh',
}));

// ----------------------------------------------------------------------

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

// ----------------------------------------------------------------------

export default function ReportLayout() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { initialize } = useAuth();
  const { isConnected } = useCheckInternet();
  const isFirstRender = useRef(true);
  const [showUpdate, setShowUpdate] = useState(false);

  // check while connecting to active internet connection ...
  useEffect(() => {
    if (isFirstRender.current) {
      // Skip the first render
      isFirstRender.current = false;
      return;
    }

    const fireInitialization = () => {
      if (isConnected) {
        initialize();
        postOfflineReportsFromIndexDB();
      }
    };

    fireInitialization();
    // eslint-disable-next-line
  }, [isConnected]);

  const { succMsg, errMsg } = useSelector((state) => state.PWASubmitReport);
  const { updateSuccessMessage, updateErrorMessage } = useSelector(
    (state) => state.PWAAllReports
  );
  const { inventoryCreateSuccMsg, inventoryCreateErrMsg } = useSelector(
    (state) => state.PWAScoutInventoryReducer
  );

  const [reportUUID, setReportUUID] = useState(null);
  const [updateReportId, setUpdateReportId] = useState(null);

  useEffect(() => {
    // use to get to know if user launches the app ...
    if (window.matchMedia('(display-mode: standalone)').matches) {
      initialize();
      if (navigator.onLine) {
        postOfflineReportsFromIndexDB();
      }
    }

    // eslint-disable-next-line
  }, [window.matchMedia('(display-mode: standalone)').matches]);

  useEffect(() => {
    serviceWorkerRegistration.register({
      onUpdate: (registration) => {
        setShowUpdate(true);
        const waitingServiceWorker = registration.waiting;

        if (waitingServiceWorker) {
          waitingServiceWorker.addEventListener('statechange', (event) => {
            if (event.target.state === 'activated') {
              window.location.reload();
            }
          });
          waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
        }
      },
    });
  }, []);

  const prevAmount = usePrevious({
    succMsg,
    errMsg,
    updateSuccessMessage,
    updateErrorMessage,
    inventoryCreateSuccMsg,
    inventoryCreateErrMsg,
  });
  useEffect(() => {
    // handlers for new reports ...
    if (succMsg && prevAmount && prevAmount.succMsg !== succMsg) {
      deleteReportFromIndexDB();
    }
    if (errMsg && prevAmount && prevAmount.errMsg !== errMsg) {
      if (errMsg === "The uuid has already been taken.") {
        deleteReportFromIndexDB();
      }
      enqueueSnackbar(errMsg, { variant: 'error' });
    }

    // handlers for updated reports ...
    if (
      updateSuccessMessage &&
      prevAmount &&
      prevAmount.updateSuccessMessage !== updateSuccessMessage
    ) {
      deleteUpdatedReportRecordFromIndexDB();
    }
    if (
      updateErrorMessage &&
      prevAmount &&
      prevAmount.updateErrorMessage !== updateErrorMessage
    ) {
      enqueueSnackbar(updateErrorMessage, { variant: 'error' });
    }

    // handlers for inventory records ...
    if (
      inventoryCreateSuccMsg &&
      prevAmount &&
      prevAmount.inventoryCreateSuccMsg !== inventoryCreateSuccMsg
    ) {
      deleteInventoryFromIndexDB();
    }
    if (
      inventoryCreateErrMsg &&
      prevAmount &&
      prevAmount.inventoryCreateErrMsg !== inventoryCreateErrMsg
    ) {
      if (inventoryCreateErrMsg === "The uuid has already been taken.") {
        deleteInventoryFromIndexDB();
      }
      enqueueSnackbar(inventoryCreateErrMsg, { variant: 'error' });
    }
    // eslint-disable-next-line
  }, [
    succMsg,
    errMsg,
    updateSuccessMessage,
    updateErrorMessage,
    inventoryCreateSuccMsg,
    inventoryCreateErrMsg,
  ]);

  // useEffect(() => {
  // trigger for testing purpose only
  //   postOfflineReportsFromIndexDB();
  // }, []);

  const postOfflineReportsFromIndexDB = async () => {
    const allOfflineReports = await getAllIndexDBRecords(
      INDEX_DB_CONFIG.offlineSubmittedReports.storeObject
    );
    const allOfflineUpdatedReports = await getAllIndexDBRecords(
      INDEX_DB_CONFIG.offlineUpdatedReports.storeObject
    );
    const allOfflineInventorisReports = await getAllIndexDBRecords(
      INDEX_DB_CONFIG.offlineInventoryReports.storeObject
    );

    if (allOfflineReports?.length) {
      postSingleScoutReport(allOfflineReports[0]);
    } else if (allOfflineUpdatedReports?.length) {
      postSingleUpdatedScoutReport(allOfflineUpdatedReports[0]);
    } else if (allOfflineInventorisReports?.length) {
      postInventoryRecords(allOfflineInventorisReports[0]);
    }
  };

  // function: new reports ...
  // eslint-disable-next-line
  const postSingleScoutReport = async (scoutReport) => {
    const { uuid = '', reportData = {}, reportAttachments = [] } = scoutReport;

    setReportUUID(uuid);

    const formData = new FormData();
    formData.append('data', JSON.stringify(reportData));
    formData.append('uuid', uuid);

    const dataURItoBlob = (dataURI) => {
      const byteString = atob(dataURI.split(',')[1]);
      const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i += 1) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: mimeString });
    };

    reportAttachments?.forEach((singleCat) => {
      if (singleCat?.captured_images?.length) {
        singleCat.captured_images.forEach((img) => {
          formData.append(
            `attachment[${singleCat?.question_item_id}][]`,
            new File([dataURItoBlob(img?.data)], img.name, {
              type: dataURItoBlob(img?.data)?.type ?? 'jpeg',
            })
          );
        });
      }
    });

    dispatch(submitReportForm(formData));
  };

  const deleteReportFromIndexDB = async () => {
    try {
      if (reportUUID) {
        await deleteIndexDBRecord(
          INDEX_DB_CONFIG.offlineSubmittedReports.storeObject,
          reportUUID
        );
        setReportUUID(null);

        postOfflineReportsFromIndexDB();
      }
    } catch (error) {
      enqueueSnackbar(
        error?.length
          ? error
          : 'something went wrong while syncing offline reports',
        {
          variant: 'error',
        }
      );
    }
  };

  // function: updated reports ...
  const postSingleUpdatedScoutReport = async (scoutReportData) => {
    const {
      id = '',
      reportData = {},
      reportAttachments = [],
      deletedAttachments = [],
    } = scoutReportData;

    setUpdateReportId(id);

    // answerData: unique image attachable records ...
    const answerData = [];
    reportAttachments?.forEach((img) => {
      const recIndex = answerData?.findIndex(
        (rec) => rec.scout_question_item_id === img.scout_question_item_id
      );

      if (recIndex === -1) {
        answerData.push({
          scout_answer_report_id: img.scout_answer_report_id,
          scout_question_item_id: img.scout_question_item_id,
        });
      } else if (
        answerData[recIndex]?.scout_answer_report_id !==
        img.scout_answer_report_id
      ) {
        answerData.push({
          scout_answer_report_id: img.scout_answer_report_id,
          scout_question_item_id: img.scout_question_item_id,
        });
      }
    });

    const updateFormData = new FormData();
    updateFormData.append('scout_report_id', id);
    updateFormData.append('data', JSON.stringify({ answer_data: answerData }));

    const shouldFireUploadAPI = answerData?.length > 0;

    const dataURItoBlob = (dataURI) => {
      const byteString = atob(dataURI.split(',')[1]);
      const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i += 1) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: mimeString });
    };

    reportAttachments?.forEach((imgEle) => {
      const imgObj = imgEle.imageData;

      updateFormData.append(
        `attachment[${imgEle?.scout_question_item_id}][]`,
        new File([dataURItoBlob(imgObj?.data)], imgObj.name, {
          type: dataURItoBlob(imgObj?.data)?.type ?? 'jpeg',
        })
      );
    });

    dispatch(
      updateExistingReport(
        JSON.stringify(reportData),
        updateFormData,
        shouldFireUploadAPI,
        deletedAttachments
      )
    );
  };

  const deleteUpdatedReportRecordFromIndexDB = async () => {
    try {
      if (updateReportId) {
        await deleteIndexDBRecord(
          INDEX_DB_CONFIG.offlineUpdatedReports.storeObject,
          updateReportId
        );
        setUpdateReportId(null);

        postOfflineReportsFromIndexDB();
      }
    } catch (error) {
      enqueueSnackbar(
        error?.length
          ? error
          : 'something went wrong while syncing offline reports',
        {
          variant: 'error',
        }
      );
    }
  };

  // function: new inventory ...
  const postInventoryRecords = async (inventoryRecord) => {
    const { uuid, reportData = {} } = inventoryRecord;

    setReportUUID(uuid);

    const formData = new FormData();
    for (const key in reportData) {
      if (Object.hasOwnProperty.call(reportData, key)) {
        const element = reportData[key];
        formData.append(key, element);
      }
    }

    dispatch(createInventory(formData));
  };

  const deleteInventoryFromIndexDB = async () => {
    try {
      if (reportUUID) {
        await deleteIndexDBRecord(
          INDEX_DB_CONFIG.offlineInventoryReports.storeObject,
          reportUUID
        );
        setReportUUID(null);

        postOfflineReportsFromIndexDB();
      }
    } catch (error) {
      enqueueSnackbar(
        error?.length
          ? error
          : 'something went wrong while syncing offline reports',
        {
          variant: 'error',
        }
      );
    }
  };

  return (
    <Page title="Report">
      <RootParentStyle>
        <RootStyle>
          <Container sx={{ px: 3 }}>
            <ContentStyle>
              {showUpdate && (
                <Box
                  sx={{ pb: 4 }}
                >
                  <Alert
                    sx={{ width: '100%' }}
                    icon={false}
                  >
                    New version available. Please refresh the app.
                    <LoadingButton
                      size="small"
                      sx={{ mt: 2 }}
                      color="inherit"
                      variant="outlined"
                      onClick={() => window.location.reload()}
                    >
                      Refresh
                    </LoadingButton>
                  </Alert>
                </Box>
              )}
              <Outlet context={{ postOfflineReportsFromIndexDB }} />
            </ContentStyle>
          </Container>
        </RootStyle>
      </RootParentStyle>
    </Page>
  );
}
